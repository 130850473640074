import {
    BroadcastOnHomeOutlinedIcon,
    EventOutlinedIcon,
    formatDate,
    HelpOutlineOutlinedIcon,
    ModeOfTravelOutlinedIcon,
} from "@enerbit/base";
import { Frontier, importExportStatus } from "../models/frontiers/Frontier";

interface FrontierInfoItem {
    label: string;
    value?: string;
    Icon: React.ElementType;
}

export const validateTypeFrontier = (
    frontiers: Frontier[],
): FrontierInfoItem[] => {
    const items: FrontierInfoItem[] = [];
    const defaultNoFrontier = {
        label: "¿Es frontera?",
        value: "No",
        Icon: HelpOutlineOutlinedIcon,
    };

    if (frontiers.length === 0) {
        items.push(defaultNoFrontier);
        return items;
    }

    const createFrontierItem = (
        label: string,
        frontier: Frontier | undefined,
        noFrontierLabel: string,
    ) => {
        if (frontier) {
            items.push(
                { label, Icon: ModeOfTravelOutlinedIcon },
                {
                    label: `${label} Frontera:`,
                    value: frontier.frontier_xm_code,
                    Icon: BroadcastOnHomeOutlinedIcon,
                },
                {
                    label: `${label} Fecha de registro ante XM:`,
                    value: formatDate(frontier.frontier_xm_registered_from),
                    Icon: EventOutlinedIcon,
                },
            );
        } else {
            items.push({
                label: noFrontierLabel,
                value: "No",
                Icon: ModeOfTravelOutlinedIcon,
            });
        }
    };

    const importFrontier = frontiers.find(
        (frontier) =>
            frontier.frontier_type === importExportStatus.ImportFrontier,
    );
    const exportFrontier = frontiers.find(
        (frontier) =>
            frontier.frontier_type === importExportStatus.ExportFrontier,
    );

    createFrontierItem(
        "Importadora",
        importFrontier,
        "¿Es frontera importadora?",
    );
    createFrontierItem(
        "Exportadora",
        exportFrontier,
        "¿Es frontera exportadora?",
    );

    if (!exportFrontier) {
        items.push(defaultNoFrontier);
    }

    return items;
};
