import {
    Box,
    Grid,
    Typography,
    enerbitColors,
    formatterPeso,
} from "@enerbit/base";
import moment from "moment";
import { Fragment } from "react";
import {
    ChargeName,
    EnerbitServiceAgreement,
    Interval,
} from "../../../models/agreement/ServiceAgreement";

export type CardEnerbitServiceAgreementProps = {
    enerbitServiceAgreement: EnerbitServiceAgreement;
    backgroundColor?: string;
};

export const CardEnerbitServiceAgreement = ({
    enerbitServiceAgreement,
    backgroundColor,
}: CardEnerbitServiceAgreementProps) => {
    return (
        <Box
            className="card-enerbit-service-agreement"
            sx={{
                backgroundColor: backgroundColor ?? enerbitColors.neutral[100],
            }}
        >
            <Typography variant="body1" fontWeight="bold">
                {enerbitServiceAgreement.name}
            </Typography>
            <Grid container>
                <Grid item xs={6}>
                    <Typography
                        variant="body1"
                        fontWeight="bold"
                        color={enerbitColors.primary.main}
                    >
                        Herramientas tecnológicas:
                    </Typography>
                    <Typography
                        variant="body1"
                        color={enerbitColors.primary.main}
                    >
                        {enerbitServiceAgreement.conditions.charges.map(
                            (charge) => {
                                if (charge.name != ChargeName.Fixed) {
                                    return "";
                                }
                                return formatterPeso.format(
                                    charge.conditions.price ?? 0,
                                );
                            },
                        )}
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography
                        variant="body1"
                        fontWeight="bold"
                        color={enerbitColors.primary.main}
                    >
                        Cobertura:
                    </Typography>
                    <Box>
                        {enerbitServiceAgreement.conditions.charges.map(
                            (charge) => {
                                if (charge.name == ChargeName.Fixed) {
                                    return <Fragment key={charge.name} />;
                                }
                                return (
                                    <Box key={charge.name}>
                                        {charge.conditions.intervals?.map(
                                            (interval) => {
                                                return (
                                                    <ItemInterval
                                                        key={interval.start}
                                                        interval={interval}
                                                        colortext={
                                                            enerbitColors
                                                                .primary.main
                                                        }
                                                    />
                                                );
                                            },
                                        )}
                                    </Box>
                                );
                            },
                        )}
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
};

// TODO: Mover código al base

type ItemIntervalProps = {
    interval: Interval;
    colortext?: string;
};
const ItemInterval = ({ interval, colortext }: ItemIntervalProps) => {
    const _getLabelText = () => {
        const start = moment(interval.start, "HH:mm").format("HH:mm");
        const duration = Number(interval.duration.toLowerCase().split("h")[0]);
        const end = moment(interval.start, "HH:mm")
            .add(duration, "hours")
            .format("HH:mm");
        return `${start} a ${end}`;
    };
    return (
        <Typography
            variant="body1"
            color={colortext ?? enerbitColors.neutral[700]}
        >
            {_getLabelText()} es de {formatterPeso.format(interval.price)}
        </Typography>
    );
};
