import { Box } from "@mui/material";
import BodyComponent from "./BodyComponent";
import TitleComponent from "./TitleComponent";

export interface Tag {
    id: string;
    name: string;
    created_at: string;
    updated_at: string;
}

type SectionCardInformationProps = {
    background?: string;
    titleColor?: string;
    bodyColor?: string;
    titleText: string;
    data: Tag[];
    IconTitle: React.ElementType;
};

const SectionCardTasks = ({
    background,
    titleColor,
    bodyColor,
    titleText,
    data,
    IconTitle,
}: SectionCardInformationProps) => (
    <Box
        sx={{
            backgroundColor: background,
            pt: "1rem",
            borderRadius: "8px",
        }}
    >
        <TitleComponent
            color={titleColor}
            IconTitle={IconTitle}
            titleText={titleText}
        />
        <BodyComponent color={bodyColor} data={data} />
    </Box>
);

export default SectionCardTasks;
