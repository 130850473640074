import {
    enerbitColors,
    LocationCityIcon,
    PersonSearchOutlinedIcon,
    SpeakerNotesOutlinedIcon,
    theme,
} from "@enerbit/base";
import AccountBoxOutlinedIcon from "@mui/icons-material/AccountBoxOutlined";
import MarkEmailUnreadOutlinedIcon from "@mui/icons-material/MarkEmailUnreadOutlined";
import { navigateToUrl } from "single-spa";
import { useUser } from "../../store/User";
import SectionCardInformation from "../CardInformationGeneral/SectionCardInformation";

export const ShowUserInformation = () => {
    const { userSelected } = useUser();

    const emails = userSelected?.usersApp?.pii?.emails;

    const phones = userSelected?.usersApp?.pii?.phones;

    return (
        <SectionCardInformation
            titleText={"Información del usuario"}
            titleColor={theme.palette.primary.main}
            bodyColor={enerbitColors?.neutral[700]}
            background={enerbitColors.neutral[100]}
            IconTitle={LocationCityIcon}
            showButtonBottom={{
                textButton: "Ver más detalle",
                shouldShowButtonBottom: true,
                onClick: () => {
                    navigateToUrl(
                        "/#/services-account?q=" +
                            userSelected?.user_id +
                            "&serviceGroup=" +
                            userSelected?.service_account_id,
                    );
                },
                Icon: SpeakerNotesOutlinedIcon,
            }}
            data={[
                {
                    label: "ID del usuario:",
                    value: userSelected?.user_id || "",
                    Icon: PersonSearchOutlinedIcon,
                },
                {
                    label: "N° identificación:",
                    value: userSelected?.usersApp?.pii?.legal_id_code || "",
                    Icon: AccountBoxOutlinedIcon,
                },
                {
                    label: "Email:",
                    value: emails ? emails[0]?.email : "",
                    Icon: MarkEmailUnreadOutlinedIcon,
                },
                {
                    label: "Número contacto:",
                    value: phones ? phones[0]?.phone : "",
                    Icon: AccountBoxOutlinedIcon,
                },
            ]}
        />
    );
};
