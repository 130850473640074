import { enerbitColors, WarningIcon } from "@enerbit/base";
import { Box, styled, Typography } from "@mui/material";

export const renderErrorMessage = (errorMessage: string) => (
    <Box sx={{ display: "flex", alignItems: "center", mt: "0.8rem" }}>
        <WarningIcon
            style={{
                color: enerbitColors.error.main,
                margin: "0 0.5rem 0 0.2rem",
            }}
        />
        <TypographyStatus>{errorMessage}</TypographyStatus>
    </Box>
);
const TypographyStatus = styled(Typography)(() => ({
    fontWeight: 400,
    color: enerbitColors.error.main,
    display: "inline",
}));
