import {
    ArrowBackIosIcon,
    Box,
    Button,
    Tab,
    Tabs,
    enerbitColors,
} from "@enerbit/base";
import { useTab } from "../../store/Tab";

type TabPanelProps = {
    children: React.ReactNode;
    value: number;
    index: number;
};

interface TabModel {
    label: string;
    component: JSX.Element;
    disabled?: boolean;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
        >
            {value === index && <Box>{children}</Box>}
        </div>
    );
}

export type MuiTabsProps = {
    tabs: TabModel[];
    disabled?: boolean;
};

export default function MuiTabs({ tabs }: MuiTabsProps) {
    const { indexTab, setIndexTab } = useTab();
    return (
        <Box sx={{ width: "100%", display: "flex" }}>
            <Box sx={{ width: "7%" }}>
                <Button
                    onClick={() => window.history.go(-1)}
                    sx={{
                        display: "flex",
                        padding: "0.6rem 0.8rem !important",
                        justifyItems: "center",
                        height: "2.7rem",
                        justifyContent: "center",
                        minWidth: "0 !important",
                        borderRadius: "8px !important",
                        background: enerbitColors.primary[100],
                    }}
                >
                    <ArrowBackIosIcon
                        sx={{
                            color: enerbitColors.neutral[700],
                            fontSize: "17px",
                        }}
                    />
                </Button>
            </Box>
            <Box sx={{ width: "93%" }}>
                <Box
                    sx={{ borderBottom: 1, borderColor: "divider", mb: "20px" }}
                >
                    <Tabs
                        value={indexTab}
                        onChange={(_, value) => setIndexTab(value)}
                        aria-label="basic tabs example"
                        TabIndicatorProps={{
                            style: {
                                backgroundColor: "orange",
                                color: "black",
                            },
                        }}
                    >
                        {tabs.map(({ label, disabled }, i) => (
                            <Tab
                                disabled={disabled}
                                className="Custom-tabs"
                                label={label}
                                key={i}
                            />
                        ))}
                    </Tabs>
                </Box>
                {tabs.map(({ component }, i) => (
                    <TabPanel value={indexTab} index={i} key={i}>
                        {component}
                    </TabPanel>
                ))}
            </Box>
        </Box>
    );
}
