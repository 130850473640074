import { api, EnerbitPagination } from "@enerbit/base";
import moment from "moment";
import { create } from "zustand";
import {
    AgreementStore,
    EnerbitServiceAgreementsRelationships,
} from "../models/stores/Agreement";

export const useAgreement = create<AgreementStore>((set, _get) => ({
    typeChangePlan: null,
    enerbitServiceAgreements: null,
    enerbitServiceAgreementPlan: null,
    startedAtAgreementPlan: moment().startOf("month"),
    setEnerbitServiceAgreementPlan: (enerbitServiceAgreementPlan) =>
        set({ enerbitServiceAgreementPlan }),
    setStartedAtAgreementPlan: (startedAtAgreementPlan) =>
        set({ startedAtAgreementPlan }),
    setTypeChangePlan: (typeChangePlan) => set({ typeChangePlan }),
    //TODOS LOS METODOS AQUI COMENTADOS SE PROBARÁN CUANDO SE HABILITE EL CAMBIO DE PLAN EN CONVENCIONAL
    getEnerbitServiceAgreements: async () => {
        // try {
        //     const { data } = await api.get<EnerbitPagination<EnerbitServiceAgreement>>(
        //         `electricity-supply-service/manager/enerbit-electricity-supply-service-agreements/`,
        //         {
        //             params: {
        //                 size: 300,
        //             },
        //         }
        //     );
        //     set({ enerbitServiceAgreements: data })
        // } catch (error) {
        //     throw new Error();
        // }
    },
    getEnerbitServiceAgreementsRelationships: async (essId) => {
        try {
            const { data } = await api.get<
                EnerbitPagination<EnerbitServiceAgreementsRelationships>
            >(
                "/electricity-supply-service/manager/enerbit-service-agreements-relationships/?enerbit_electricity_supply_service_id=" +
                    essId,
            );
            return data;
        } catch (error) {
            throw error;
        }
    },
    postChangeAgreement: async (_params) => {
        // const { data, status } = await api.post(
        //     `electricity-supply-service/manager/enerbit-service-agreements-relationships/change-plan/`,
        //     params
        // );
        // if (status === 200 && data) {
        //     get().updateServiceEss(params.to_service_agreement_id);
        //     return data;
        // }
    },
    postAssignAgreement: async (_params) => {
        // const { data, status } = await api.post(
        //     `electricity-supply-service/manager/enerbit-service-agreements-relationships/`,
        //     params
        // );
        // if (status === 200 && data) {
        //     location.reload();
        // }
    },
    postCreateAgreement: async (_params) => {
        // const { data, status } = await api.post(
        //     `electricity-supply-service/manager/enerbit-electricity-supply-service`,
        //     params
        // );
        // if (status === 200 && data) {
        //     location.reload();
        // }
    },
    updateServiceEss: (_agreement) => {
        // const newAgreement = get().enerbitServiceAgreements?.items.find(
        //     (e) => e.id === agreement
        // );
        // const service = useEssManager.getState().ESSservice;
        // if (service?.enerbit_electricity_supply_service) {
        //     useEssManager.setState({
        //         ESSservice: {
        //             ...service, enerbit_electricity_supply_service: {
        //                 ...service.enerbit_electricity_supply_service,
        //                 enerbit_service_agreement: newAgreement!,
        //             }
        //         }
        //     });
        // }
    },
}));
