import { forwardRef, useImperativeHandle, useRef, useState } from "react";

import { CustomModal, CustomModalTypes, LoadingButton } from "@enerbit/base";
import { Box, Button } from "@mui/material";
import { useAgreement } from "../../../store/Agreement";
import { useEssManager } from "../../../store/EssManager";
import { getTitleConfirmModal } from "../../../utils/LabelsModal";

const ConfirmChange = forwardRef((_props, ref) => {
    const createClient = useRef<CustomModalTypes>(null);
    const { typeChangePlan } = useAgreement();

    useImperativeHandle(ref, () => ({
        changeModal() {
            if (createClient.current) {
                createClient.current.changeModal();
            }
        },
    }));

    const {
        postChangeAgreement,
        postAssignAgreement,
        postCreateAgreement,
        enerbitServiceAgreementPlan,
        startedAtAgreementPlan,
    } = useAgreement();
    const { ESSservice } = useEssManager();
    const [isChangeOrCreateAgreement, setIsChangeOrCreateAgreement] =
        useState(false);

    const dispatchChangeTypePlan = async () => {
        setIsChangeOrCreateAgreement(true);
        if (typeChangePlan === "alreadyHasAPlan") {
            await postChangeAgreement({
                electricity_supply_service_id: ESSservice?.id ?? "",
                to_service_agreement_id: enerbitServiceAgreementPlan?.id ?? "",
                started_at: startedAtAgreementPlan.toISOString(),
            });
        } else if (typeChangePlan === "assignPlan") {
            await postAssignAgreement({
                enerbit_electricity_supply_service_id:
                    ESSservice?.enerbit_electricity_supply_service?.id ?? "",
                enerbit_electricity_supply_service_agreement_id:
                    enerbitServiceAgreementPlan?.id ?? "",
                fixed_enerbit_electricity_supply_services_id:
                    ESSservice?.fixed_enerbit_electricity_supply_service?.id ??
                    "",
                started_at: startedAtAgreementPlan.toISOString(),
            });
        } else if (typeChangePlan === "createService") {
            await postCreateAgreement({
                user_id: ESSservice?.owner_id ?? "",
                electricity_supply_service_id: ESSservice?.id ?? "",
                cuttable: ESSservice?.cuttable ?? false,
                service_status_id: ESSservice?.service_status_id ?? "",
                started_at: startedAtAgreementPlan.toISOString(),
                service_agreement_id: enerbitServiceAgreementPlan?.id ?? "",
                ended_at: null,
            });
        }
        setIsChangeOrCreateAgreement(false);
        createClient.current?.changeModal(); //se cierra modal no se identifica que se muestre ninguna alerta en el codigo raiz
    };

    return (
        <CustomModal
            maxWidth="xs"
            ref={createClient}
            onClose={() => {
                //dispatch(onResetVars());
            }}
            dialogContent={
                <>
                    <Box
                        sx={{ fontWeight: "400", mb: "0.5rem" }}
                        className="Modal-title"
                    >
                        {enerbitServiceAgreementPlan &&
                            getTitleConfirmModal(
                                typeChangePlan!,
                                enerbitServiceAgreementPlan.name,
                            )}
                    </Box>
                    <Box className="Modal-button-container">
                        <Button
                            color="warning"
                            size="small"
                            className="Modal-button-logout-cancel"
                            onClick={() => {
                                if (createClient.current) {
                                    createClient.current.changeModal();
                                }
                            }}
                            sx={{ width: "40%" }}
                        >
                            <Box style={{ fontWeight: "bold", margin: 0 }}>
                                Volver
                            </Box>
                        </Button>

                        <LoadingButton
                            fullWidth
                            variant="contained"
                            color="secondary"
                            disabled={false}
                            loading={isChangeOrCreateAgreement}
                            onClick={() => {
                                dispatchChangeTypePlan();
                            }}
                        >
                            Guardar
                        </LoadingButton>
                    </Box>
                </>
            }
        />
    );
});

export default ConfirmChange;
