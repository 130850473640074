import { create } from "zustand";

interface Tab {
    indexTab: number;
    setIndexTab: (indexTab: number) => void;
}

export const useTab = create<Tab>((set) => ({
    indexTab: 0,
    setIndexTab: (indexTab) => set({ indexTab }),
}));
