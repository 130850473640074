import {
    BoltOutlinedIcon,
    Box,
    ContentPasteSearchOutlinedIcon,
    enerbitColors,
    Loader,
    LocationCityIcon,
    MapsHomeWorkOutlinedIcon,
    ModeOfTravelOutlinedIcon,
    theme,
    VillaOutlinedIcon,
} from "@enerbit/base";
import { ReactNode } from "react";
import { useLeftMenuData } from "../../hooks/LeftMenuData";
import { RootParams } from "../../models/root-params/RootParams";
import { useEssManager, useFrontier, useTab, useUser } from "../../store";
import { validateTypeFrontier } from "../../utils/Frontiers";
import SectionCardInformation from "../CardInformationGeneral/SectionCardInformation";
import SectionCardTasks from "../CardInformationTasks/SectionCardTasks";
import { CustomizedSelect } from "../CustomizedSelect/CustomizedSelect";
import { EnerbitServiceAgreementCard } from "../ServiceAgreements/EnerbitServiceAgreementCard";
import { ShowUserInformation } from "../ShowUserInformation/ShowUserInformation";

const LoaderWrapper = ({
    isLoading,
    children,
    message,
}: {
    isLoading: boolean;
    children: ReactNode;
    message: string;
}) => {
    return isLoading ? <Loader message={message} /> : <>{children}</>;
};
const LeftMenu = ({
    id,
    titleText = "Información del servicio",
    dataServiceInfo,
    statusCard,
}: RootParams) => {
    const { setIndexTab } = useTab();
    const { ESSservice } = useEssManager();
    const { usersApp } = useUser();
    const { frontiers } = useFrontier();

    const estateInfo = ESSservice?.estate
        ? [
              {
                  label: "Dirección:",
                  value: ESSservice.estate.address,
                  Icon: MapsHomeWorkOutlinedIcon,
              },
              {
                  label: "Departamento:",
                  value: ESSservice.estate.state,
                  Icon: VillaOutlinedIcon,
              },
              {
                  label: "Ciudad:",
                  value: ESSservice.estate.city,
                  Icon: VillaOutlinedIcon,
              },
              {
                  label: "Estrato:",
                  value: ESSservice.estate.details.social_stratum.description,
                  Icon: VillaOutlinedIcon,
              },
          ]
        : [];

    const { isLoadingUsersApp, isLoadingFrontier, isLoadingService } =
        useLeftMenuData(id);

    return (
        <Box
            bgcolor="white"
            sx={{ boxShadow: "2px 1px 9px 0px rgba(250,250,250,1)" }}
        >
            <Box
                sx={{
                    padding: "1.6rem 1.25rem 1.25rem 2.25rem",
                    width: "354px",
                }}
            >
                <LoaderWrapper
                    isLoading={isLoadingService}
                    message="Cargando información del servicio"
                >
                    <Box>
                        <LoaderWrapper
                            isLoading={isLoadingUsersApp}
                            message="Cargando Información de los usuarios"
                        >
                            {usersApp.length > 0 && <CustomizedSelect />}
                        </LoaderWrapper>

                        {ESSservice?.tags && (
                            <SectionCardTasks
                                titleText="Etiquetas del servicio"
                                titleColor={theme.palette.primary.main}
                                bodyColor={enerbitColors?.information[800]}
                                data={ESSservice.tags}
                                IconTitle={BoltOutlinedIcon}
                            />
                        )}
                        {usersApp.length > 0 && <ShowUserInformation />}
                        {statusCard}
                        <SectionCardInformation
                            titleText={titleText}
                            titleColor={theme.palette.secondary.main}
                            bodyColor={enerbitColors?.neutral[700]}
                            background="#FFF1E6"
                            IconTitle={ContentPasteSearchOutlinedIcon}
                            showButtonTop
                            onClick={() => setIndexTab(1)}
                            data={dataServiceInfo}
                        />

                        <SectionCardInformation
                            titleText="Información del predio"
                            titleColor={enerbitColors?.primary[600]}
                            bodyColor={enerbitColors?.neutral[700]}
                            background={enerbitColors?.primary[200]}
                            IconTitle={LocationCityIcon}
                            showButtonTop
                            onClick={() => setIndexTab(1)}
                            data={estateInfo}
                        />

                        {!isLoadingUsersApp &&
                            !isLoadingFrontier &&
                            !isLoadingService && (
                                <EnerbitServiceAgreementCard
                                    enerbitElectricitySupplyService={
                                        ESSservice?.enerbit_electricity_supply_service
                                    }
                                />
                            )}

                        <LoaderWrapper
                            isLoading={isLoadingFrontier}
                            message="Cargando Información de la frontera"
                        >
                            {frontiers.length > 0 && (
                                <SectionCardInformation
                                    titleText="Información de la frontera"
                                    titleColor={enerbitColors?.information.main}
                                    bodyColor={enerbitColors?.neutral[700]}
                                    background={enerbitColors?.information[100]}
                                    IconTitle={ModeOfTravelOutlinedIcon}
                                    data={validateTypeFrontier(frontiers)}
                                />
                            )}
                        </LoaderWrapper>
                    </Box>
                </LoaderWrapper>
            </Box>
        </Box>
    );
};

export default LeftMenu;
