import { useEffect, useState } from "react";
import { useEssManager, useFrontier, useUser } from "../store";

export const useLeftMenuData = (id: string) => {
    const { getUsersApp } = useUser();
    const { getFrontiers } = useFrontier();
    const { getEssById, ESSservice } = useEssManager();

    const [isLoadingUsersApp, setIsLoadingUsersApp] = useState(false);
    const [isLoadingFrontier, setIsLoadingFrontier] = useState(false);
    const [isLoadingService, setIsLoadingService] = useState(false);

    const fetchUsersServiceAccount = async () => {
        if (!ESSservice) return;
        setIsLoadingUsersApp(true);
        await getUsersApp(ESSservice.service_account_id);
        setIsLoadingUsersApp(false);
    };

    const fetchFrontierInfo = async () => {
        if (!ESSservice) return;
        setIsLoadingFrontier(true);
        await getFrontiers(ESSservice.measurement_point_id);
        setIsLoadingFrontier(false);
    };

    const fetchESSManager = async () => {
        setIsLoadingService(true);
        await getEssById(id);
        setIsLoadingService(false);
    };

    useEffect(() => {
        fetchESSManager();
    }, [id]);

    useEffect(() => {
        fetchUsersServiceAccount();
        fetchFrontierInfo();
    }, [ESSservice]);

    return {
        isLoadingUsersApp,
        isLoadingFrontier,
        isLoadingService,
    };
};
