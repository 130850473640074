import { api } from "@enerbit/base";
import { enqueueSnackbar } from "notistack";
import { create } from "zustand";
import {
    AppUsers,
    ServicesAccountItem,
} from "../models/accounts/ServicesAccount";
import { getServiceAccountsRelationships } from "../services/ServiceAccountRelationships";

interface User {
    userSelected: ServicesAccountItem | null;
    usersApp: ServicesAccountItem[];
    setUserSelected: (user: ServicesAccountItem) => void;
    getUsersApp: (idServiceAccount: string) => Promise<void>;
}

export const useUser = create<User>((set) => ({
    userSelected: null,
    usersApp: [],
    setUserSelected: (user) => set({ userSelected: user }),
    getUsersApp: async (idServiceAccount: string) => {
        try {
            const service =
                await getServiceAccountsRelationships(idServiceAccount);
            if (service?.items) {
                const updatedItems = await Promise.all(
                    service?.items.map(async (user) => {
                        const { data } = await api.get<AppUsers>(
                            "/users/app-users/" + user.user_id,
                        );
                        return { ...user, usersApp: data };
                    }),
                );
                service.items = updatedItems;
                set({ usersApp: service.items });
            }
        } catch (error) {
            enqueueSnackbar("Ocurrió un error al cargar los usuarios", {
                variant: "error",
            });
            throw error;
        }
    },
}));
