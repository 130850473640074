import { api, EnerbitPagination } from "@enerbit/base";
import { create } from "zustand";
import { Frontier } from "../models/frontiers/Frontier";

interface FrontierData {
    frontiers: Frontier[];
    getFrontiers: (measurementPointId: string) => Promise<void>;
}

const initialState = {
    frontiers: [],
};

export const useFrontier = create<FrontierData>((set) => ({
    ...initialState,
    getFrontiers: async (measurementPointId) => {
        try {
            const { data } = await api.get<EnerbitPagination<Frontier>>(
                `assignees/frontiers?measurement_point_id=${measurementPointId}`,
            );
            set({
                frontiers: data.items,
            });
        } catch (error) {
            throw error;
        }
    },
}));
