import {
    DifferenceOutlinedIcon,
    EventOutlinedIcon,
    InsertChartOutlinedIcon,
    ModeOfTravelOutlinedIcon,
    RuleFolderOutlinedIcon,
    enerbitColors,
} from "@enerbit/base";
import moment from "moment";
import { useEffect, useState } from "react";
import { EnerbitServiceAgreementsRelationships as ESSServiceAgreement } from "../../models/agreement/ServiceAgreement";
import { EnerbitServiceAgreementsRelationships } from "../../models/stores/Agreement";
import { useAgreement } from "../../store";
import {
    enerBitPlanGenerationAndUnitCostCoverage,
    enerBitPlanTechToolCharges,
} from "../../utils/EnerBitPlansUtils";
import SectionCardInformation from "../CardInformationGeneral/SectionCardInformation";
import { ModalChangePlan } from "../modal-change-plan/ModalChangePlan";

export type EnerbitServiceAgreementCardProps = {
    enerbitElectricitySupplyService?: ESSServiceAgreement | null;
};

export const EnerbitServiceAgreementCard = ({
    enerbitElectricitySupplyService,
}: EnerbitServiceAgreementCardProps) => {
    const [open, setOpen] = useState(false);
    const { setTypeChangePlan, getEnerbitServiceAgreementsRelationships } =
        useAgreement();
    const [enerbitPlanStartDate, setEnerbitPlanStartDate] =
        useState<EnerbitServiceAgreementsRelationships>();

    useEffect(() => {
        if (!enerbitElectricitySupplyService) {
            setTypeChangePlan("createService");
        } else if (!enerbitElectricitySupplyService.enerbit_service_agreement) {
            setTypeChangePlan("assignPlan");
        } else {
            setTypeChangePlan("alreadyHasAPlan");
        }
    }, [enerbitElectricitySupplyService]);

    const getServiceAgreement = async () => {
        getEnerbitServiceAgreementsRelationships(
            enerbitElectricitySupplyService!.id,
        ).then((serviceAgreement) => {
            const enerbitPlanStartDate = serviceAgreement?.items.find(
                (item) => item.is_active === true,
            );
            setEnerbitPlanStartDate(enerbitPlanStartDate);
        });
    };

    useEffect(() => {
        if (enerbitElectricitySupplyService?.id) {
            getServiceAgreement();
        }
    }, [enerbitElectricitySupplyService?.id]);

    const enerbitServiceAgreementsRelationshipsError = "";

    return (
        <>
            {enerbitElectricitySupplyService?.enerbit_service_agreement && (
                <SectionCardInformation
                    titleText={"Información del plan"}
                    titleColor={"#E26A04"}
                    bodyColor={enerbitColors?.neutral[700]}
                    background={"#FFFDDB"}
                    IconTitle={ModeOfTravelOutlinedIcon}
                    // showButtonTop={false}
                    // showButtonBottom={{
                    //   shouldShowButtonBottom: true,
                    //   textButton:
                    //     typeChangePlan === "alreadyHasAPlan"
                    //       ? "Cambiar plan de servicio eB"
                    //       : "",
                    //   onClick: () => {
                    //     typeChangePlan === "alreadyHasAPlan" && setOpen(true);
                    //   },
                    //   Icon: InsertChartOutlinedIcon,
                    // }}
                    data={[
                        {
                            label: "Plan actual:",
                            value:
                                enerbitElectricitySupplyService
                                    ?.enerbit_service_agreement?.name ??
                                "No se encuentra nombre de plan",
                            Icon: RuleFolderOutlinedIcon,
                        },
                        {
                            label: "Fecha inicio:",
                            value: enerbitPlanStartDate
                                ? moment(
                                      enerbitPlanStartDate.started_at,
                                  ).format("YYYY-MM-DD")
                                : enerbitServiceAgreementsRelationshipsError,
                            Icon: EventOutlinedIcon,
                        },
                        {
                            label: "Fecha fin:",
                            value: enerbitPlanStartDate?.ended_at
                                ? moment(enerbitPlanStartDate?.ended_at).format(
                                      "YYYY-MM-DD",
                                  )
                                : "Indefinido",
                            Icon: EventOutlinedIcon,
                        },
                        {
                            label: "Cobertura:",
                            value: enerBitPlanGenerationAndUnitCostCoverage({
                                enerbitElectricitySupplyService,
                            }),
                            Icon: DifferenceOutlinedIcon,
                        },
                        {
                            label: "Herramientas tec:",
                            value: enerBitPlanTechToolCharges({
                                enerbitElectricitySupplyService,
                            }),
                            Icon: InsertChartOutlinedIcon,
                        },
                    ]}
                />
            )}

            {/* {typeChangePlan === "createService" && (
        <Box className="custom-card-box">
          <Button
            fullWidth
            variant="outlined"
            color="primary"
            onClick={() => {
              setOpen(true);
            }}
          >
            Crear servicio eB
          </Button>
        </Box>
      )}

      {typeChangePlan === "assignPlan" && (
        <Box className="custom-card-box">
          <Button
            fullWidth
            variant="outlined"
            color="primary"
            onClick={() => {
              setOpen(true);
            }}
          >
            Asignar plan a servicio eB
          </Button>
        </Box>
      )} */}

            <ModalChangePlan
                open={open}
                handleClose={() => {
                    setOpen(false);
                }}
            />
        </>
    );
};
