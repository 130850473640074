import { api } from "@enerbit/base";
import { create } from "zustand";
import { Service } from "../models/electricity-supply-services/ServiceInformation";

interface FrontierData {
    ESSservice: Service | null;
    getEssById: (id: string) => Promise<void>;
}

const initialState = {
    ESSservice: null,
};

export const useEssManager = create<FrontierData>((set) => ({
    ...initialState,
    getEssById: async (id) => {
        try {
            const { data } = await api.get<Service>(
                `/electricity-supply-service/manager/electricty-supply-services/${id}`,
            );
            set({
                ESSservice: data,
            });
        } catch (error) {
            throw error;
        }
    },
}));
