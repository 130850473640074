import { ThemeConfig, initEnviroment } from "@enerbit/base";
import "./assets/css/leftmenu.scss";
import { RootParams } from "./models/root-params/RootParams";
import LeftMenuServicePage from "./pages/LeftMenuService";

initEnviroment({
    baseUrl: process.env.REACT_APP_BASE_URL,
});

export default function Root(params: RootParams) {
    return (
        <ThemeConfig>
            <LeftMenuServicePage {...params} />
        </ThemeConfig>
    );
}
