import { api } from "@enerbit/base";
import { ServicesAccount } from "../models/accounts/ServicesAccount";

export const getServiceAccountsRelationships = async (
    idServiceAccount: string,
) => {
    try {
        const { data } = await api.get<ServicesAccount>(
            "accounts/service-accounts-relationships/?service_account_id=" +
                idServiceAccount +
                "&page=0&size=50",
        );
        return data;
    } catch (error) {
        throw error;
    }
};
