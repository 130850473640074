import { ArrowForwardIosOutlinedIcon, Button } from "@enerbit/base";
import { Box, Typography } from "@mui/material";

type BodyComponentProps = {
    color?: string;
    data: DataItem[];
    titleColor?: string;
};

export type DataItem = {
    label: string;
    value?: number | string | Date | React.ReactNode;
    Icon: React.ElementType;
    showButtonItem?: ShowButtonItemProps;
};

interface ShowButtonItemProps {
    shouldShowButton: boolean;
    onClick: () => void;
}

const BodyComponent = ({ color, data, titleColor }: BodyComponentProps) => (
    <Box>
        {data.map((item, index) =>
            item.showButtonItem?.shouldShowButton ? (
                <Button
                    key={index}
                    sx={{
                        display: "flex",
                        width: "100%",
                        flexDirection: "row",
                        marginBottom: "0.3rem",
                        flexWrap: "wrap",
                        justifyContent: "flex-start",
                        padding: "0.6rem 0rem 0.6rem 0 !important",
                        "&:hover": {
                            color: titleColor,
                            background: "none",
                            "& .MuiTypography-root, & .MuiSvgIcon-root, & span":
                                {
                                    background: "none",
                                    color: titleColor,
                                },
                        },
                    }}
                    onClick={item.showButtonItem.onClick}
                >
                    <Box
                        sx={{
                            display: "flex",
                            flexWrap: "wrap",
                            width: "100%",
                        }}
                    >
                        <Box
                            component="span"
                            sx={{
                                color: color,
                                fontWeight: "bold",
                                marginLeft: "0px",
                                display: "flex",
                                flexWrap: "nowrap",
                                alignItems: "center",
                                width: "auto",
                            }}
                        >
                            <item.Icon
                                sx={{
                                    color: color,
                                    fontSize: "14px",
                                    marginRight: "0.2rem",
                                }}
                            />
                            <Typography
                                component="span"
                                sx={{
                                    display: "flex",
                                    fontWeight: "bold",
                                    alignItems: "center",
                                    flexWrap: "wrap",
                                    width: "100%",
                                }}
                            >
                                {item.label}
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                width: "auto",
                            }}
                        >
                            <Typography
                                component="span"
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    flexWrap: "wrap",
                                    marginRight: "0.7rem",
                                    width: "100%",
                                }}
                            >
                                {typeof item.value === "object" &&
                                !(item.value instanceof Date) ? (
                                    <Box
                                        component="span"
                                        sx={{ margin: "0 0.3 0 1rem" }}
                                    >
                                        {item.value}
                                    </Box>
                                ) : item.value instanceof Date ? (
                                    <Box
                                        component="span"
                                        sx={{ marginLeft: "0.3rem" }}
                                    >
                                        {item.value.toLocaleDateString()}
                                    </Box>
                                ) : (
                                    <Box
                                        component="span"
                                        sx={{
                                            color: color,
                                            fontWeight: "400",
                                            marginLeft: "0.3rem",
                                        }}
                                    >
                                        {item.value}
                                    </Box>
                                )}
                            </Typography>
                            <ArrowForwardIosOutlinedIcon
                                sx={{ color: color, fontSize: "0.7rem" }}
                            />
                        </Box>
                    </Box>
                </Button>
            ) : (
                <Box
                    key={index}
                    sx={{
                        display: "flex",
                        marginBottom: "0.3rem",
                        flexWrap: "wrap",
                    }}
                >
                    <Box
                        component="span"
                        sx={{
                            color: color,
                            fontWeight: "bold",
                            marginLeft: "2px",
                            display: "flex",
                            alignItems: "center",
                        }}
                    >
                        <item.Icon
                            sx={{
                                color: color,
                                fontSize: "12px",
                                marginRight: "0.2rem",
                            }}
                        />
                        {item.label}
                    </Box>
                    <Typography
                        component="span"
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            flexWrap: "wrap",
                        }}
                    >
                        {typeof item.value === "object" &&
                        !(item.value instanceof Date) ? (
                            <Box
                                component="span"
                                sx={{ margin: "0 0.3 0 1rem" }}
                            >
                                {item.value}
                            </Box>
                        ) : item.value instanceof Date ? (
                            <Box component="span" sx={{ marginLeft: "0.3rem" }}>
                                {item.value.toLocaleDateString()}
                            </Box>
                        ) : (
                            <Box
                                component="span"
                                sx={{
                                    color: color,
                                    fontWeight: "400",
                                    marginLeft: "0.3rem",
                                }}
                            >
                                {item.value}
                            </Box>
                        )}
                    </Typography>
                </Box>
            ),
        )}
    </Box>
);

export default BodyComponent;
